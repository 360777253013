









































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseTextarea,
  DefaLoaderCircle,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import type { Nullable, UserWithPublicRGInfo } from "@defa-as/utils";
import PublicRGStatusError from "@/components/public-rg/PublicRGStatusError.vue";
import { userModule } from "@/store/modules/user";
import PublicRGUserContactInfo from "@/components/public-rg/PublicRGUserContactInfo.vue";
import {
  denyRetailerPublicForPublicRg,
  getUserInfo,
} from "@/http/requests/requests-user";

@Component({
  components: {
    PublicRGUserContactInfo,
    PublicRGStatusError,
    DefaBaseTextarea,
    DefaBaseButton,
    DefaLoaderCircle,
    DefaTransitionFadeOutIn,
  },
})
export default class ViewDenyUserForPublicRG extends Vue {
  @Prop({ required: true }) readonly userId!: string;
  user: Nullable<UserWithPublicRGInfo> = null;
  loading = {
    user: false,
    deny: false,
  };
  model = {
    comment: "",
  };
  success = false;

  async created() {
    try {
      this.loading.user = true;
      this.user = await getUserInfo<UserWithPublicRGInfo>(this.userId, true);
    } finally {
      this.loading.user = false;
    }
  }

  get adminRulingExists() {
    return this.user ? userModule.adminRulingExists(this.user) : false;
  }

  get userEmail() {
    return this.user?.email;
  }

  get userEmailLink() {
    if (this.userEmail) {
      return `mailto:${this.userEmail}`;
    }
    return null;
  }

  async onSubmit() {
    this.loading.deny = true;
    try {
      await denyRetailerPublicForPublicRg(this.userId, {
        adminComment: this.model.comment,
      });
      this.success = true;
    } finally {
      this.loading.deny = false;
    }
  }
}
