









import { Component, Prop, Vue } from "vue-property-decorator";
import type { User } from "@defa-as/utils";

@Component
export default class PublicRGUserContactInfo extends Vue {
  @Prop({ required: true }) readonly user!: User;

  get userEmail() {
    return this.user.email;
  }

  get userTel() {
    return this.user.telephoneNumber;
  }

  get userEmailLink() {
    return `mailto:${this.userEmail}`;
  }

  get userTelLink() {
    return `tel:${this.userTel}`;
  }
}
