




















import { Component, Prop, Vue } from "vue-property-decorator";
import type { UserWithPublicRGInfo } from "@defa-as/utils";
import { formatDate } from "@defa-as/utils";

@Component
export default class PublicRGStatusError extends Vue {
  @Prop({ required: true, type: Object }) readonly user!: UserWithPublicRGInfo;

  get translationPath() {
    const adminRulingLowercase = this.user.publicRg.adminRuling.toLowerCase();
    return this.user.publicRg.adminComment
      ? `rgStatusError.${adminRulingLowercase}.withComment`
      : `rgStatusError.${adminRulingLowercase}.noComment`;
  }

  get adminEmailLink() {
    return `mailto:${this.user.publicRg.admin}`;
  }

  get adminRulingDateFormatted() {
    return formatDate(new Date(this.user.publicRg.adminRulingDate));
  }

  get adminComment() {
    return this.user.publicRg.adminComment;
  }
}
